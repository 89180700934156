<template>
  <!-- Content -->
  <div class="page-content">
    <div v-bind:class="getClass">
      <slot></slot>
    </div>
  </div>
  <!-- ./Content -->
</template>

<script>
export default {
  name: "WeContent",
  computed: {
    getClass() {
      let result = "container";

      if (this.fluid) {
        result = "container-fluid mb-5";
      } else if (this.wide) {
        result = "container wide-container";
      }

      return result;
    },
  },
  props: ["fluid", "wide"],
};
</script>

<style lang="scss">
.page-content {
  position: relative;
  flex: 1 0 auto;
}
</style>